<div class="border-special">
  <button
    (click)="onClick()"
    [className]="
      getClassName() +
      'w-full min-w-[80px] min-h-[40px] flex items-center justify-center p-1 gap-2 '
    "
    [ngClass]="{ 'hover:opacity-90': !disabled() }"
    [disabled]="disabled()"
  >
    <ng-content select="[startAdornment]"></ng-content>{{ label() }}
    @if(loading()) {
    <i class="pi pi-spin pi-spinner" style="font-size: 1rem"></i>
    } @else {
    <ng-content select="[endAdornment]"></ng-content>

    }
  </button>
</div>
